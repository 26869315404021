import toastr from 'toastr'
import ApplicationController from './application_controller'
import configureToastr from './toastr_config'

export default class extends ApplicationController {
  connect() {
    super.connect()
    configureToastr()
    document.addEventListener('toastr', (event) => {
      toastr.success(event.detail.message)
    })
  }
}
