// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// import { Controller } from 'stimulus'
import $ from 'jquery'
import ApplicationController from './application_controller'
import 'select2/dist/js/select2.full.min'

import '../styles/pages.scss'

export default class extends ApplicationController {
  static targets = ['variant', 'variantOption', 'select']

  selectTargetConnected(element) {
    // $(document).ready(() => {
    //   $('.select2bs4').select2({
    //     theme: 'bootstrap4',
    //     width: 'resolve'
    //   })
    // // }).on('select2:select', () => {
    // //   this.select_device(element)
    // })
  }

  select_variant() {
    const variants = document.querySelectorAll('[id*="variant"] label')
    Array.from(variants).forEach((el) => {
      el.classList.remove('active')
    })
    this.variantTarget.classList.add('active')
  }

  select_variant_option() {
    const variants = document.querySelectorAll('[id*="variant-options"] label')
    Array.from(variants).forEach((el) => {
      el.classList.remove('active')
    })
    this.variantOptionTarget.classList.add('active')
  }

  select_device() {
    const productSelects = document.querySelectorAll('select[id^=\'selection_\']')
    const productIds = Array.from(productSelects, (product) => product.value)
    Array.from(productSelects).forEach((el) => {
      el.dataset.productIds = productIds.toString()
      console.log(`product_ids: ${el.dataset.productIds}`)
    })
    this.stimulate('ProductVariant#change_device')
  }
}
