// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus'
import Radiolabel from 'radiolabel'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, {
  consumer, controller, isolate: true
})

if (process.env.RAILS_ENV !== 'production') {
  StimulusReflex.debug = true
}

if (process.env.RAILS_ENV === 'development') {
  import('radiolabel').then((Radiolabel) => application.register('radiolabel', Radiolabel.default))
}
