// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'trix'
import 'trix/dist/trix.css'
import 'CSSBox/cssbox.css'

// Bootstrap
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'

// clipboard.js
import ClipboardJS from 'clipboard/dist/clipboard.min'

// styles
import '../styles/application.scss'
import '@fortawesome/fontawesome-free/css/all.css'

// Load Stimulus controllers
import '../controllers'

// lazy load images
import 'lazysizes'

require('@rails/ujs').start()
// require('turbolinks').start()
require('../channels')

$(document).ready(() => {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body'
  })

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover'
  })

  // clipboard.js settings
  $('.copyButton').tooltip({
    trigger: 'click',
    placement: 'bottom'
  })

  function setTooltip(btn, message) {
    $(btn).tooltip('hide').attr('data-original-title', message).tooltip('show')
  }

  function hideTooltip(btn) {
    setTimeout(() => {
      $(btn).tooltip('hide')
    }, 1000)
  }

  const clipboard = new ClipboardJS('.copyButton')

  clipboard.on('success', (e) => {
    setTooltip(e.trigger, '已複製至剪貼簿')
    hideTooltip(e.trigger)
  })

  clipboard.on('error', (e) => {
    setTooltip(e.trigger, '複製錯誤')
    hideTooltip(e.trigger)
  })

  // FB share button
  const getWindowOptions = function () {
    const width = 500
    const height = 450
    const left = (window.innerWidth / 2) - (width / 2)
    const top = (window.innerHeight / 2) - (height / 2)

    return ['resizable,scrollbars,status', `height=${height}`, `width=${width}`, `left=${left}`, `top=${top}`].join()
  }
  const fbBtn = document.querySelector('.facebook-share')
  if (fbBtn !== null) {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
    fbBtn.href = shareUrl // 1

    fbBtn.addEventListener('click', (e) => {
      e.preventDefault()
      const win = window.open(shareUrl, 'ShareOnFb', getWindowOptions())
      win.opener = null // 2
    })
  }

  // Validate email on user registration
  $('.invalid-email').hide()
  $('.valid-email').hide()

  $('#email').on('blur', (event) => {
    $.ajax({
      url: `/email_validator/${$('#email').val()}`,
      type: 'GET',
      dataType: 'json',
      error(jqXHR, textStatus, errorThrown) {
      },
      success(data, textStatus, jqXHR) {
        if (data.valid === true) {
          $('.invalid-email').hide()
          $('.register-button').prop('disabled', false)
          return $('.valid-email').show()
        }
        if (data.valid === false) {
          $('.valid-email').hide()
          $('.register-button').prop('disabled', true)
          return $('.invalid-email').show()
        }
      }
    })
    event.stopImmediatePropagation()
    return false
  })
})

$(document).ready(() => {
  // const toggleAffix = function (affixElement, scrollElement, wrapper) {
  //   const height = affixElement.outerHeight()
  //   //    top = wrapper.offset().top;
  //
  //   if (scrollElement.scrollTop() >= 80) {
  //     wrapper.height(height)
  //     affixElement.addClass('affix')
  //   } else {
  //     affixElement.removeClass('affix')
  //     wrapper.height('auto')
  //   }
  // }
  //
  // $('[data-toggle="affix"]').each(function () {
  //   const ele = $(this)
  //   const wrapper = $('<div></div>')
  //
  //   ele.before(wrapper)
  //   $(window).on('scroll resize', function () {
  //     toggleAffix(ele, $(this), wrapper)
  //   })
  //
  //   // init
  //   toggleAffix(ele, $(window), wrapper)
  // })
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
