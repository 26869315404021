import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'productSelect',
    'skuSelect',
    'submitButton',
    'couponCode',
    'couponMessage',
    'couponDescription',
    'couponGroup'
  ]

  static values = {
    eventUuid: String
  }

  connect() {
    this.updateSubmitButton()
  }

  updateSkus() {
    const productId = this.productSelectTarget.value
    if (productId) {
      fetch(`/products/${productId}/skus`)
        .then((response) => response.json())
        .then((data) => {
          if (this.hasSkuSelectTarget) {
            this.skuSelectTarget.innerHTML = '<option value="">請選擇規格</option>'
            data.forEach((sku) => {
              const option = document.createElement('option')
              option.value = sku.id
              option.textContent = sku.display_name
              option.dataset.quantity = sku.quantity
              this.skuSelectTarget.appendChild(option)
            })
          }
          this.updateSubmitButton()
        })
    } else {
      if (this.hasSkuSelectTarget) {
        this.skuSelectTarget.innerHTML = '<option value="">請選擇規格</option>'
      }
      this.updateSubmitButton()
    }
  }

  toggleCouponInput() {
    const productId = this.productSelectTarget.value
    const couponInput = this.couponCodeTarget
    const applyButton = this.couponGroupTarget.querySelector('button')

    if (productId) {
      couponInput.disabled = false
      applyButton.disabled = false
    } else {
      couponInput.disabled = true
      applyButton.disabled = true
    }

    // Clear the coupon code and related messages whenever the product changes
    couponInput.value = ''
    this.couponMessageTarget.textContent = ''
    this.couponDescriptionTarget.textContent = ''
    this.couponDescriptionTarget.classList.add('d-none')
  }

  updateSubmitButton() {
    if (!this.hasSubmitButtonTarget) return

    if (this.hasSkuSelectTarget) {
      const selectedSku = this.skuSelectTarget.selectedOptions[0]
      if (selectedSku && parseInt(selectedSku.dataset.quantity) > 0) {
        this.submitButtonTarget.disabled = false
      } else {
        this.submitButtonTarget.disabled = true
      }
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  validateCoupon(event) {
    event.preventDefault()
    if (!this.hasCouponCodeTarget || !this.hasCouponMessageTarget) return

    const couponCode = this.couponCodeTarget.value
    const productId = this.productSelectTarget.value
    const eventUuid = this.eventUuidValue
    const url = '/pre_order_events/validate_coupon'

    fetch(`${url}?coupon_code=${couponCode}&product_id=${productId}&uuid=${eventUuid}`, {
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.couponMessageTarget.textContent = data.message
        this.couponMessageTarget.classList.toggle('text-success', data.valid)
        this.couponMessageTarget.classList.toggle('text-danger', !data.valid)

        if (this.hasCouponDescriptionTarget) {
          if (data.valid && data.description) {
            this.couponDescriptionTarget.textContent = data.description
            this.couponDescriptionTarget.classList.remove('d-none')
          } else {
            this.couponDescriptionTarget.textContent = ''
            this.couponDescriptionTarget.classList.add('d-none')
          }
        }

        // Clear the coupon code input if it's invalid
        if (!data.valid) {
          this.couponCodeTarget.value = ''
        }
      })
  }
}
