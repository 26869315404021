import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['mainForm', 'mobileForm', 'verificationForm', 'nameForm', 'devVerificationCode', 'codeDisplay'];

  static values = {
    mobile: String
  };

  async checkMobile(event) {
    event.preventDefault()
    const mobile = event.target.querySelector('#user_mobile').value
    this.mobileValue = mobile // Store the mobile number
    const storeId = this.getStoreIdFromUrl()

    const response = await fetch('/check_mobile', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        mobile,
        store_id: storeId
      })
    })
    const data = await response.json()

    if (data.verified) {
      const signInSuccess = await this.signInUser(data.user_id)
      if (signInSuccess) {
        // this.showMainForm();
        window.location.reload()
      }
    } else if (data.user_created) {
      this.showVerificationForm(data.verification_code)
    } else {
      alert('無效的手機號碼')
    }
  }

  showVerificationForm(verificationCode) {
    this.verificationFormTarget.classList.remove('d-none')
    this.mobileFormTarget.classList.add('d-none')
    if (verificationCode && this.hasDevVerificationCodeTarget) {
      this.devVerificationCodeTarget.classList.remove('d-none')
      this.codeDisplayTarget.textContent = verificationCode
    }
  }

  async verifyCode(event) {
    event.preventDefault()
    const code = event.target.querySelector('#user_verification_code').value
    const response = await fetch('/verify_code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        code
      })
    })
    const data = await response.json()

    if (data.verified) {
      this.nameFormTarget.classList.remove('d-none')
      this.verificationFormTarget.classList.add('d-none')
    } else {
      alert('無效的驗證碼')
    }
  }

  async updateName(event) {
    event.preventDefault()
    const name = event.target.querySelector('#user_name').value
    const response = await fetch('/update_name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        mobile: this.mobileValue,
        name
      })
    })
    const data = await response.json()

    if (data.success) {
      const signInSuccess = await this.signInUser(data.user_id)
      if (signInSuccess) {
        window.location.reload()
        // this.showMainForm()
      }
    } else {
      alert('更新姓名失敗')
    }
  }

  async signInUser(userId) {
    const response = await fetch('/users/sign_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        user: {
          id: userId
        },
        preOrder: true
      })
    })

    if (response.ok) {
      const data = await response.json()
      if (data.status === 'ok') {
        return true
      }
    }
    alert('登入失敗')
    return false
  }

  // showMainForm() {
  //   this.mainFormTarget.classList.remove('d-none')
  //   this.mobileFormTarget.classList.add('d-none')
  //   this.verificationFormTarget.classList.add('d-none')
  //   this.nameFormTarget.classList.add('d-none')
  //   if (this.hasDevVerificationCodeTarget) {
  //     this.devVerificationCodeTarget.classList.add('d-none')
  //   }
  // }

  getStoreIdFromUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('store_id')
  }
}
